// App.js
import React from "react";
import Login from "./login";

function App() {
  return (
    <div className="app">
      <div
        style={{ marginTop: "0%", display: "flex", flexDirection: "row" }}
      >
        <Login />
      </div>
    </div>
  );
}

export default App;
