// TextEditor.js
import React, { useState } from "react";
import Container from "@mui/material/Container";
import TextField from '@mui/material/TextField';
import { Button } from "@mui/material";
import axios from "axios";
import Typography from "@mui/material/Typography";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import {
  EditorState,
  ContentState,
  Modifier,
  RichUtils,
} from "draft-js";
import { Editor } from "react-draft-wysiwyg";

function TextEditor(props) {
  const [editorString,setEditorString] = React.useState("")
  const [alterPrompt,setAlterPrompt] = React.useState("")
  const [sopContent, setSopContent] = React.useState(props.props.sopContent)
  const [loading, setLoading] = React.useState(false)
  const [selectedState, setSelectedState] = React.useState("");
  const [highlighted, setHighlighted] = React.useState({"Visitor Visa":false,"Study Visa":false})
  const data = EditorState.createWithContent(
    ContentState.createFromText(editorString)
  );
  
  const [editorState, setEditorState] = useState(data);
  
  const handleHighlight = () => {
      const newEditorState = RichUtils.toggleInlineStyle(editorState, 'HIGHLIGHT');
      setEditorState(newEditorState)
  };

  const getEditorString = async (newSectionContent,use_case) => {
    const value = newSectionContent[use_case]
    if(value){
      setEditorString(value);
    } 
  }
  
  const improveSop = async (use_case,alterPrompt) => {
    try{
      if(alterPrompt && sopContent){
        const userDetails = JSON.stringify(props.props.formValues)
        var sop_content = ""  
          try {
            var bodyFormData = new FormData();
            bodyFormData.append('user_details',userDetails);
            bodyFormData.append('use_case', use_case);
            bodyFormData.append('alterprompt', alterPrompt);
            bodyFormData.append('sop', sopContent[use_case]);
            if(selectedState){
              bodyFormData.append('selected_section', selectedState);
              const response = await axios.post('https://nuronai.inteligenai.com/sopgen/editSelectedSop', bodyFormData);
              const contentState = editorState.getCurrentContent();
              const selection = editorState.getSelection();
              const newContentState = Modifier.replaceText(
                contentState,
                selection,
                response.data["response: "]
              );
              const neweditstate = EditorState.createWithContent(newContentState);
              setEditorState(neweditstate)
              sop_content = neweditstate.getCurrentContent().getPlainText();
            }
            else{
              const response = await axios.post('https://nuronai.inteligenai.com/sopgen/editWholeSop', bodyFormData);
              sop_content = response.data["response: "]
              const editState = EditorState.createWithContent(
                ContentState.createFromText(sop_content)
              );
              setEditorState(editState)
            }
            props.props.sopContent[props.props.use_case] = sop_content 
          } 
          catch (error) {
            console.error('Error sending api request');
            sop_content = "Some problem occured in backend. we will be back shortly! :)"
            // setSelectedState("")
          }
        // setSelectedState("")
        setSopContent({
          ...sopContent, 
          [use_case]: sop_content
         });
      }
    }
    catch(e){
      console.log("Exception in editinng the existing sop: ",e)
    }
  }
  const selectionState = editorState.getSelection();
  const anchorKey = selectionState.getAnchorKey();
  const currentContent = editorState.getCurrentContent();
  const currentContentBlock = currentContent.getBlockForKey(anchorKey);
  const start = selectionState.getStartOffset();
  const end = selectionState.getEndOffset();
  const selectedText = currentContentBlock.getText().slice(start, end);
  const getCurrentTextSelection = () => {
    const selectionState = editorState.getSelection();
    const anchorKey = selectionState.getAnchorKey();
    const currentContent = editorState.getCurrentContent();
    const currentContentBlock = currentContent.getBlockForKey(anchorKey);
    const start = selectionState.getStartOffset();
    const end = selectionState.getEndOffset();
    const selectedText = currentContentBlock.getText().slice(start, end);
    setSelectedState(selectedText);
    return selectedText;
  };

  const updateTextDescription = async (state) => {
    await setEditorState(state);
    const data = state.getCurrentContent().getPlainText();
    getCurrentTextSelection();
    setSopContent({
      ...sopContent,
      [props.props.use_case]:data}
    )
  };
  
  React.useEffect(() => {
    const newSopContent = {
      ...sopContent,
      [props.props.use_case]:props.props.sopContent[props.props.use_case]
    }

    setSopContent(newSopContent)
    getEditorString(props.props.sopContent,props.props.use_case)
  }, [props.props.sopContent]);
  
  React.useEffect(() => {
    setEditorState(
      EditorState.createWithContent(ContentState.createFromText(editorString))
    );
  }, [editorString])

  React.useEffect(() => {
    setEditorState(
      EditorState.createWithContent(ContentState.createFromText(sopContent[props.props.use_case]))
    );
  }, [props.props.use_case]);

  const improoveMarginTop = "91%";
  const styleMap = {
    'HIGHLIGHT': {
      'backgroundColor': '#cccccc',
    },
    'UNHIGHLIGHT': {
      'backgroundColor': 'white',
    }
  };

  return (
    <Container width="100%">
      {
        loading &&
        <div style={{display:"flex", flexDirection:"column", justifyContent:"center", alignItems:"center", marginBottom:"-200px"}}>
          <img style={{marginBottom:"0px"}} src="https://i.pinimg.com/originals/3b/36/4f/3b364fb5ca22e7b9dc2683a6d77ad35c.gif" height="70%" width="70%" alt="loading..." />
          <Typography style={{position:"absolute", marginTop:"20%"}}>{(props.props.use_case==="Visitor Visa")?"Editing Cover Letter with required changes. It won't take much of your time!":"Editing Statement of Purpose with required changes. It won't take much of your time!"}</Typography>
        </div>
      }
      {
        loading===false &&
        <div>
        <Editor
          editorState={editorState}
          toolbarClassName="toolbarClassName"
          wrapperClassName="wrapperClassName"
          editorClassName="editorClassName"
          onEditorStateChange={updateTextDescription}
          customStyleMap={styleMap}
          onChange={() => {if(highlighted[props.props.formValues.visa_type]){handleHighlight(); setHighlighted({...highlighted,[props.props.formValues.visa_type]:false});}}}
        />
        </div>
      }
      {editorString!=="" && 
      <div style={{display:"flex", flexDirection:"row", align:"center", position:"absolute", width:"67%", top:improoveMarginTop, zIndex: 2}} >
      <TextField size='small' style={{marginBottom: "1%", width: "72%", marginRight: "2%", backgroundColor:"#FFFFFF"}} id="alter_prompt" value={alterPrompt} onClick={() => { if(highlighted[props.props.formValues.visa_type]===false){handleHighlight(); setHighlighted({...highlighted,[props.props.formValues.visa_type]:true});}}} onChange={(e) => { setAlterPrompt(e.target.value);}} label="What do you want to improve?" variant="outlined" />
      <Button size="small" style={{backgroundColor:"#ea3321", fontWeight:"bold", marginBottom: "1%", width: "25%"}} id="generate_updated_sop" variant="contained" onClick={async (e) => {setLoading(true); await improveSop(props.props.use_case,alterPrompt); setAlterPrompt(""); setLoading(false); }}>Improve</Button>
      </div>
      }
    </Container>
  );
}

export default TextEditor;
