// App.js
import * as React from "react";
import Playground from "./Playground";
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import LoginIcon from '@mui/icons-material/Login';
import PropTypes from "prop-types";
import backgroundimg from "./public/AI_Letter_Writing_Tool_4.png";
import Snackbar from '@mui/material/Snackbar';

function Login(props) {
  const [authenticated, setAuthentication] = React.useState(false);
  const [alertState, setAlertState] = React.useState(false);
  const [username, setusername] = React.useState("");
  const [password, setpassword] = React.useState("");
  return (
    <div className="Login" style={{}}>
    { authenticated===false &&
      <div id="main-bg-image" style={{ height:"100vh", width:"100vw", backgroundImage:`url(${backgroundimg})`, backgroundSize:"cover", backgroundRepeat:"no-repeat"}}>
        <div id="sign-up"
        style={{ display: "flex", marginLeft: "3.5%",width:"50%",height:"20%", flexDirection: "column"}}
        >
        <TextField  id="username-input" style={{marginTop:"47%", marginBottom:"25px"}} value={username} label="Username" onChange={(e) => setusername(e.target.value)} variant="outlined" />
        <TextField id="password-input" style={{marginBottom:"25px"}} value={password} label="Password" type="password" onChange={(e) => setpassword(e.target.value)} />
        <Button variant="contained" style={{color: "#003f5a", width:"50%"}} onClick={() => {const usernames=JSON.parse(process.env.REACT_APP_LOGIN_USERNAME); const passwords= JSON.parse(process.env.REACT_APP_LOGIN_PASSWORD); if(usernames.includes(username) && password===passwords[username]){ setAuthentication(true)}else{setAlertState(true)}}} endIcon={<LoginIcon />}>Login</Button>
      </div>
      {
        alertState && 
        <Snackbar
        anchorOrigin={{vertical:"top", horizontal:"center"}}
        open={alertState}
        onClose={() => {setAlertState(false)}}
        message="username or password entered is incorrect"
        key="bottom_center_sanckbar"/>
      }
      </div>
      }
      {
        authenticated &&
        <Playground/>
      }
    </div>
  );
}
Login.propTypes = {
  window: PropTypes.func,
};
export default Login;